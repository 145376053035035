import { useState } from "react";

const useScroll = (element, ref, margin = 0) => {
    const [scrollCount, setScrollCount] = useState(0);
    const [containerWidth, setContainerWidth] = useState(0);

    const scrollToNext = () => {
        const boxWidth = document.querySelector(`${element}`).clientWidth;
        let positionAux;
        positionAux = containerWidth + boxWidth + margin;
        setScrollCount(scrollCount + 1);
        setContainerWidth(positionAux)
        ref.current.getElement().scroll({
            left: positionAux,
            behavior: "smooth",
        });
    }

    const scrollToPrevious = () => {
        const boxWidth = document.querySelector(`${element}`).clientWidth;
        let positionAux;
        positionAux = containerWidth - boxWidth - margin;
        setScrollCount(scrollCount - 1);
        setContainerWidth(positionAux)
        ref.current.getElement().scroll({
            left: positionAux,
            behavior: "smooth",
        });
    }

    return { scrollToNext, scrollToPrevious, setScrollCount, scrollCount}
}

export default useScroll;