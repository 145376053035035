import React from "react";
import { useRef } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import useScroll from "../hooks/useScroll";
import BenefitCard from "./benefitCard";

const NearBenefitList = (props) => {
  const { benefits, status } = props;
  const { t } = useTranslation();
  const container = useRef();
  const { scrollToNext, scrollToPrevious, scrollCount } = useScroll("#benefit-list a:nth-of-type(1)", container);

  return (
    <>

      <ScrollContainer ref={container} style={{ marginBottom: "-10px", overflow: "auto", display: "flex", marginTop: "9px" }}>
        <AiOutlineArrowRight
          className="arrow-icon-list icon-rigth spot-card-icon-right"
          onClick={() => {
            scrollToNext();
          }}
          hidden={benefits.length === 0 || scrollCount === benefits.length - 3}
        />
        <AiOutlineArrowLeft className="arrow-icon-list icon-left spot-card-icon-left" onClick={() => scrollToPrevious()} hidden={ scrollCount === 0} />
        <div id="benefit-list">
          {
            benefits.map((benefit, index) => {
              return <BenefitCard key={index} benefit={benefit} logged={status === "logged"}/>;
            })
          }
        </div>
      </ScrollContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  status: state.status,
  currentTime: state.currentTime,
});

export default connect(mapStateToProps)(NearBenefitList);
