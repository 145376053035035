import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Input, InputGroupText, InputGroup } from "reactstrap";
import styled from "styled-components";
import { AiOutlineSearch } from "react-icons/ai";
import utils from "../utils/utils";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import spotService from "../modules/spotService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faXmark } from "@fortawesome/free-solid-svg-icons";
import '../utils/styles/searchbar.css';
import constant from "../utils/constant";
import store from "../utils/store";
import searchViewReducer from "../reducers/SearchViewReducer";

const SearchBarComponent = (props) => {
  const {t} = useTranslation();

  return (
    <>
      <div className="container-xl">
        <SearchBarContainer onClick={() => searchViewReducer.toggleSearchView()}>
          <InputGroup style={{flexWrap: "nowrap"}}>
            <Input
              type="text"
              autoFocus={false}
              placeholder={t("global_search_placeholder")}
              className={props.status !== "logged" ? "bar-reduce" : ""}
            />
            <InputGroupText>
              <AiOutlineSearch />
            </InputGroupText>
          </InputGroup>
        </SearchBarContainer>
      </div>
    </>
  );
};

const SearchBarContainer = styled.div`
  width: 100%;
  padding-left: 0px;
  margin-left: 30px;

  @media (max-width: 1000px) {
    margin-left: 0px !important;
  }

  input {
    width: 265px !important;
    background-color: #ffffff !important;
    color: #355482;
    border: 1px solid #355482 !important;

    @media (max-width: 1000px) {
      width: 100px !important;
    }
  }

  .bar-reduce {
    width: 200px !important;

    @media (max-width: 1000px) {
      width: 100px !important;
    }
  }

  .input-group-text {
    padding: 0 0.5rem !important;
    background-color: #004989;
    border: 1px solid #004989;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  input:focus {
    color: #355482;
  }

  input::placeholder {
    color: #35548294;
    font-size: 0.8em;
    font-family: "poppins";
  }

  svg {
    width: 23px;
    height: 23px;
    z-index: 99;
    color: #ffffff;
  }

  li {
    margin-bottom: 5px;
    cursor: pointer;
  }

  .icon-cam {
    width: 14px;
    margin-left: 0px;
    margin-bottom: 3px;
  }

  .spot-detail {
    font-size: 0.8em;
  }

  .spot-name {
    font-family: "poppins";
    font-size: 0.9em;
  }

  .spinner-border {
    width: 1rem !important;
    height: 1rem !important;
    padding: 10px;
    color: #004989;
    margin-left: 40% !important;
  }

  .spot-error {
    font-size: 0.8em;
    font-family: "poppins";
  }

  a {
    color: #355482 !important;
  }
`;

const mapStateToProps = (state) => ({
  status: state.status,
  user: state.user,
  countryCode: state.country,
  searchbarList: state.searchbarList,
});

export default connect(mapStateToProps)(SearchBarComponent);
