import {useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import store from '../utils/store';

const LocationTracker = () => {
  const location = useLocation();
  useEffect(() => {    
    const actual = location.pathname;
    const last = store.getState()?.routeHistory?.actual;
    
    store.dispatch({
      type: "ROUTE_HISTORY",
      data: 
      {
        actual: actual,
        last: last,
      },
    })
  }, [location])

  return null;
}

export default LocationTracker;