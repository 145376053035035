import React, { useEffect, useState, useRef, Fragment } from "react";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import styled from "styled-components";
import ScrollContainer from "react-indiana-drag-scroll";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import SpotCard from "./spotCard";
import { Link } from "react-router-dom";
import useScroll from "../hooks/useScroll";
import Skeleton from "react-loading-skeleton";

const SfListComponent = (props) => {
  const { t } = useTranslation();
  const [surfcamsData, setSurfcamsData ] = useState([]);
  const [loading, setLoading] = useState(true);
  const container = useRef();
  const {scrollToNext, scrollToPrevious, scrollCount} = useScroll( "#sf-list a:nth-of-type(1)", container );


  useEffect(() => {
    setSurfcamsData(props.sfs)
    setLoading(false);
  },[])

  return (
    <>
          <div style={{position: "relative"}}>
          <ScrollContainer style={{ display: "flex", marginTop: "5px" }} ref={container}>
            <div id="sf-list" style={{display: "flex", width: "100%"}}>
              <AiOutlineArrowRight className="arrow-icon-list spot-card-icon-right" onClick={() => scrollToNext()} hidden={scrollCount === surfcamsData.length - 2} />
              <AiOutlineArrowLeft className="arrow-icon-list spot-card-icon-left" onClick={() => scrollToPrevious()} hidden={scrollCount === 0} />
              {
              loading ?
                <>
                  <Skeleton height={100} width={330} style={{ marginRight: "30px", borderRadius: "10px" }} />
                  <Skeleton height={100} width={330} style={{ marginRight: "30px", borderRadius: "10px" }} />
                  <Skeleton height={100} width={330} style={{ marginRight: "30px", borderRadius: "10px" }} />
                </>
              :
                surfcamsData.map((item, index) => {
                  return (
                    <Fragment key={"sfcamList" + index}>
                      {props.selectedCam.id !== item.id && (
                        <SpotCard spot={item} />
                      )}
                    </Fragment>
                  );
                })
              }
              <div className="spot-card-items-container">
                <div className="spot-card-items bold" style={{height: "100px"}}>
                  <Link to={"/spots"} style={{width: "100%", textAlign: "center"}}>
                    {t("spots_list_all")}                                
                  </Link>
                  </div>
              </div>
            </div>
          </ScrollContainer>
          </div>
        </>
  );
};

const CamsBox = styled.div`
  margin-top: 27px;
`;

const CamsTitle = styled.span`
  font-size: 1em;
  padding: 5px 10px;
  padding-left: 10px;
  background-color: black;
  color: #fff;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;

  img {
    width: 20px;
    margin-right: 4px;
    margin-top: -3px;
    filter: brightness(10) contrast() hue-rotate(217deg) saturate(0);
  }
`;

const mapStateToProps = (state) => ({
  lan: state.lan,
  surfcams: state.surfcams,
});

export default connect(mapStateToProps)(SfListComponent);
