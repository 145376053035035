import React, { useEffect, useState, Fragment, useRef } from "react";
import styled from "styled-components";
import utils from "../utils/utils";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { BsFillPersonFill } from "react-icons/bs";
import { AiOutlineInfoCircle, AiFillStar } from "react-icons/ai";
import ClockComponent from "./clockComponent";
import ForecastRegisterComponent from "./forecastRegisterComponent";
import ForecastLimitedPlanComponent from "./forecastLimitedPlanComponent";
import ForecastComponent from "./forecastComponent";
import store from "../utils/store";
import constant from "../utils/constant";
import ScrollContainer from "react-indiana-drag-scroll";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { Link } from "react-router-dom";
import useScroll from "../hooks/useScroll";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGears } from '@fortawesome/free-solid-svg-icons';

const ForecastWeekComponent = (props) => {
  const { t, i18n } = useTranslation();
  const isMobile = utils.isUsingMobile();
  const [forcastDays, setForcastDays] = useState([]);
  const [showInterpreted, setShowInterpreted] = useState(true);
  const [hasInterpreter, setHasInterpreter] = useState(false);
  const [interpreterData, setInterpreterData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const container = useRef();
  const forcastRef = useRef();
  const { scrollToNext, scrollToPrevious, scrollCount } = useScroll(".forecast-row div:nth-of-type(1)", container);

  useEffect(() => {
    if (props.spot && forcastDays.length === 0 && isLoading === true) {
      checkInterpeter();
      formatDays(props.spot);
      setIsLoading(false);
    }
  });

  const formatDays = (data) => {
    let result = [];
    let index = 0;
    Object.keys(data.data).forEach(function (key) {
      let currentDate = new Date(key);
      currentDate.setDate(currentDate.getDate() + 1);
      let currentDateName = currentDate.toLocaleString(i18n.language, { weekday: "long" });
      let currentDateValue = currentDateName + " " + currentDate.getDate();
      if (index === 0 && currentDate.getDate() == new Date().getDate()) {
        currentDateValue = t("_TODAY");
      }
      if (index === 1 && currentDate.getDate() == new Date().getDate() + 1) {
        currentDateValue = t("_TOMORROW");
      }
      result.push(currentDateValue);
      index = index + 1;
    });
    setForcastDays(result);
  };

  const checkInterpeter = () => {
    let interpreterResult = [];
    if (props.spot.interpreter) {
      Object.keys(props.spot.interpreter).forEach(function (key) {
        let value = props.spot.interpreter[key];
        const text = value["morning"][i18n.language];
        if (text !== "" && text !== undefined) {
          interpreterResult.push(value);
        }
      });

      if (interpreterResult.length > 0) {
        setHasInterpreter(true);
      } else {
        setShowInterpreted(false);
      }
      setInterpreterData(interpreterResult);
    }
  };

  const openFeedback = () => {
    store.dispatch({
      type: "NPS_ACTIVE",
      data: {
        npsActive: true,
        npsType: constant["NPS_TYPE"]["INTERPRETER"],
        npsSpot: props.spot.id,
      },
    });
  };

  return (
    <>
      {interpreterData !== null && props.spot && (
        <>
          {/* <p className="spot-orientation">
            {t("spot_orientation")}: {props.spot.orientation}
          </p>
          <Link className="underline-link" to={`/spots/country/${props.spot.country}/${props.spot.orientation}`}>
            {t("same_orientation_button") + props.spot.name}
          </Link>
          <ClockComponent timezone={props.spot.timezone} /> */}
          {/* <div className="align-item-center-div">
            <ForecastType>
              <li
                onClick={() => setShowInterpreted(false)}
                className={showInterpreted === false || (((props.user && props.user.isPremium === false) || props.status !== "logged") && isMobile === false) ? "active-forecast" : "default-forecast"}
                id="gtm-forecast-data">
                <AiOutlineInfoCircle /> {t("forecast_type_data")}
              </li>
              <li
                onClick={() => setShowInterpreted(true)}
                id="gtm-forecast-interpreter"
                className={
                  (hasInterpreter === false || ((props.user.isPremium === false || props.status !== "logged") && isMobile === false) ? "disable-events" : "") +
                  " " +
                  (showInterpreted === true ? "active-forecast" : "default-forecast")
                }>
                <FontAwesomeIcon icon={faGears} /> {t("forecast_type_interpreter")}
              </li>
            </ForecastType>
          </div> */}
          {/* <SourceText>{t(showInterpreted === true && ((props.user && props.user.isPremium === true) || isMobile === true) ? "" : "used_models")}</SourceText> */}

          <ButtonContainer id="forecastWeek-auto">
            <AiOutlineArrowRight className="arrow-icon-list icon-rigth " onClick={() => scrollToNext()} hidden={scrollCount === Object.keys(props.spot["data"]).length - 2} />
            <AiOutlineArrowLeft className="arrow-icon-list icon-left " onClick={() => scrollToPrevious()} hidden={scrollCount === 0} />
            <ScrollContainer ref={container} className="scroll-container">
              <div className="row forecast-row">
                {Object.keys(props.spot["data"]).map((key, index) => {
                  return (
                    <Fragment key={"homeForecast" + index}>
                      {index === 0 && (
                        <div className="col-lg-6" id={forcastDays[index]}>
                          <ForecastComponent
                            needBlur={false}
                            forecasting={props.spot["data"][key]}
                            day={forcastDays[index]}
                            interpreterData={interpreterData.length > 0 ? interpreterData[index] : null}
                            isInterpreter={showInterpreted}
                            selectCurrentHour={true}
                            index={index}
                            ref={forcastRef}
                          />
                        </div>
                      )}
                      {index !== 0 && (
                        <div className="col-lg-6" id={forcastDays[index]}>
                          {" "}
                          <ForecastComponent
                            needBlur={
                              (props.user && props.user.isPremium !== true && index >= 3) ||
                              (props.status !== "logged" && index > 1) ||
                              (props.status === "logged" &&
                                props.user.currentPlanId === constant.SUBSCRIPTION.FREE &&
                                ((showInterpreted === true && index > 0) || (showInterpreted === false && index >= 3)))
                            }
                            forecasting={
                              (props.user && props.user.isPremium !== true && index >= 3) || (props.status !== "logged" && index > 1) ? utils.createSkeletonForecast() : props.spot["data"][key]
                            }
                            day={forcastDays[index]}
                            interpreterData={interpreterData.length > 0 ? props.user && props.user.isPremium ? interpreterData[index] : interpreterData[0] : null}
                            isInterpreter={true}
                            selectCurrentHour={false}
                            index={index}
                            needBlurRating={(!props.user || (props.user && props.user.isPremium !== true)) && index > 0}
                          />
                        </div>
                      )}
                    </Fragment>
                  );
                })}
                <ForecastRegisterComponent spotName={props.spot.name} existData={props.spot["data"]} />
                <ForecastLimitedPlanComponent spotName={props.spot.name} existData={props.spot["data"]} />
              </div>
            </ScrollContainer>
          </ButtonContainer>
        </>
      )}
    </>
  );
};

const ButtonContainer = styled.div`
  position: relative;

  svg{
    top: 0;
    bottom 0;
    margin: auto 0;
  }

  .icon-left{ left: -35px;}
  .icon-rigth{ right: -35px;}
  
  @media(max-width: 1000px){
    .icon-left{
      display:flex;
      left: -10px;
      top: -20px;
    }
    .icon-rigth{
      display:flex;
      right: -10px;
      top: -20px;
    }
    
  }
`;

const ButtonRating = styled.button`
  margin-bottom: 20px;
  margin-top: 20px;
  background-color: #425781;
  border: none;
  color: #fff;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  text-align: center;
  align-self: auto;
  font-size: 0.9em;

  svg {
    color: #fff !important;
    margin: 0 !important;
    width: 20px !important;
    height: 20px !important;
  }
`;

const SourceText = styled.p`
  margin-top: 30px;
  font-size: 12px;
  font-family: "poppins";
  text-align: center;
  font-style: italic;
`;

const ForecastType = styled.ul`
  display: inline-flex;
  padding: 0px;
  li {
    list-style: none;
    padding: 10px 20px;
    width: 174px;
    margin-bottom: -18px;
    width: 175px;
    text-align: center;
    cursor: pointer;

    svg {
      margin: 0px;
      margin-top: -5px;
    }
  }

  li:nth-child(1) {
    border-top-left-radius: 10px;
  }

  li:nth-child(2) {
    border-top-right-radius: 10px;
  }

  .active-forecast {
    background-color: #425781;
    color: #fff;
    svg {
      color: #fff;
    }
  }

  .default-forecast {
    color: #425781;
    border: 2px solid #425781;

    svg {
      color: #425781;
    }
  }
`;

const mapStateToProps = (state) => ({
  status: state.status,
  user: state.user,
  surfcams: state.surfcams,
});

export default connect(mapStateToProps)(React.memo(ForecastWeekComponent));
