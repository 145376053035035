import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
import styled from "styled-components";
import gtmService from "../modules/gtmService";
import payment from "../modules/payment";
import checkoutReducer from "../reducers/checkoutReducer";
import constant from "../utils/constant";
import affiliateService from "../modules/AffiliateService";
import paymentErrorReducer from "../reducers/paymentErrorReducer";

const BillingComponent = (props) => {
  const { isHundredPercentDiscount, discount: discountProp, selectedPlan, referrerId, goToUrl, status, user } = props;
  let history = useHistory();
  const { t, i18n } = useTranslation();
  const [loadingScript, setLoadingScript] = useState(true);

  // eslint-disable-next-line no-undef
  const dlocalInstance = dlocal(process.env.REACT_APP_PAYMENT_KEY);
  const [country, setCountry] = useState("Uruguay");

  const [cardInstance, setCardInstance] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const createBillingInstance = () => {
    // eslint-disable-next-line no-undef
    const fields = dlocalInstance.fields({
      country: "UY",
      locale: i18n.language,
      fonts: [
        {
          cssSrc: "https://rsms.me/inter/inter-ui.css",
        },
      ],
    });
    const cardField = fields.create("card", {
      style: {
        base: {
          fontSize: "16px",
          fontFamily: "'Inter UI medium', sans-serif",
          lineHeight: "18px",
          fontSmoothing: "antialiased",
          fontWeight: "500",
          color: "#32325d",
          "::placeholder": {
            color: "#aab7c4",
          },
          iconColor: "#adbfd3",
        },
      },
    });
    setCardInstance(cardField);
    cardField.mount(document.getElementById("container"));
  };

  useEffect(() => {
    if (cardInstance === null) {
      setLoadingScript(false);
      createBillingInstance();
    }
  }, [setLoadingScript, createBillingInstance]);

  useEffect(() => {
    setCountry(user.country);
  }, []);

  const createCardToken = async () => {
    setIsLoading(true);
    setError("");
    setDisabled(true);

    const name = document.getElementById("name").value;
    const userDocument = String(document.getElementById("userDocument").value);

    if ((name === "" || userDocument === "") && !isHundredPercentDiscount) {
      setError(t("login_and_register_modal_error_fields"));
      setIsLoading(false);
      return;
    }

    const affiliate = affiliateService.getAffiliate();
    const discountId = discountProp?.id || "";

    let response = null;

    try {
      if (isHundredPercentDiscount) {
        response = await payment.subscribe("", selectedPlan.id, "", country, "", "", "", discountId, "", referrerId, affiliate);
      } else {

        const result = await dlocalInstance.createToken(cardInstance, { name: name }).catch((err) => {
          setError(t("subscription_success_error_form"));
          return null;
        });
        
        if(!result){
          setIsLoading(false);
          return;
        }

        const token = result.token;
        response = await payment.subscribe(token, selectedPlan.id, name, country, "", "", "", discountId, userDocument, referrerId, affiliate);  

      }
      if (response && response.code === 200) {
        paymentErrorReducer.savePaymentStatus(false);
        gtmService.sendEvent("purchase");
        checkoutReducer.checkout();
        history.push({
          pathname: goToUrl,
          search: window.location.search,
          state: { status: status, user: user, checkoutSuccess: true },
        });
      } else {
        handleError(response);
      }
    } catch (error) {
      handleError(error);
    }
    setIsLoading(false);
  };

  const handleError = (error) => {
    switch (error?.data?.status_code) {
      case "315":
        setError(t("subscription_success_error_invalid_card_credentials"));
        break;
      case "314":
        setError(t("subscription_success_error_invalid_card_number"));
        break;
      case "302":
        setError(t("subscription_success_error_insufficient_amount"));
        break;
      case "301" || "317":
        setError(t("subscription_success_error_rejected_bank"));
        break;
      case "321":
        setError(t("subscription_success_error_in_acquirer"));
        break;
      case "319":
        setError(t("subscription_success_error_amount_exceeded"));
        break;
      case "318":
        setError(t("subscription_success_error_invalid_transaction"));
      break;
      case "316":
        setError(t("subscription_success_error_unsupported_operation"));
        break;
      case "313":
        setError(t("subscription_success_error_card_reported_stolen"));
        break;
      case "312":
        setError(t("subscription_success_error_card_restricted_by_the_bank"));
        break;
      case "311":
        setError(t("subscription_success_error_card_requested_by_the_bank"));
        break;
      case "310":
        setError(t("subscription_success_error_card_reported_lost"));
        break;
      case "309":
        setError(t("subscription_success_error_card_expired"));
        break;
      default:
        setError(t("subscription_success_error"));
    }
    setDisabled(false);
  };

  const verifyDocumentInputLength = () => {
    let value = document.getElementById("userDocument").value;
    if (value.length > 12) {
      document.getElementById("userDocument").value = value.substring(0, value.length - 1);
    } else {
      value = value.replace(/[^0-9]/g, '');
      document.getElementById("userDocument").value = value;
    }
  };

  const getButtonText = () => {
    if (isLoading) {
      return <Spinner />;
    } else if (user && user.canUseFreeTrial && !user.isPremium && !isHundredPercentDiscount) {
      return t("subscription_billing_button");
    } else {
      return t("subscription_billing_get");
    }
  };

  return (
    <>
      <BillingContainer>
        <div style={isHundredPercentDiscount ? { display: "none" } : {}}>
          <TextError>{error}</TextError>
          <h6>{t("subscription_billing_info")}</h6>
          <div className="field half">
            <BillingInput type="text" placeholder={t("subscription_billing_name")} id="name" autoFocus />
          </div>
          <div className="field half">
            <BillingInput onInput={() => verifyDocumentInputLength()} type="text" placeholder={t("subscription_billing_document")} id="userDocument" min="0" step="1" />
          </div>
          <h6>{t("subscription_billing_card")}</h6>
          <div id="container"></div>
          {user.country === 'AR' && (
              <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10px"}}>
                <MercadoPagoLink target="_blank" href="https://www.instagram.com/reel/C7PlkTuRRTs/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==">{t("i_want_to_use_mercado_pago")}</MercadoPagoLink>
              </div>
          )}
        </div>
        <BillingButton disabled={isLoading || disabled} onClick={() => createCardToken()}>
          {getButtonText()}
        </BillingButton>
      </BillingContainer>
    </>
  );
};

const MercadoPagoLink = styled.a`
  color: #007bff;
  font-weight: 500;
`

const BillingSubTotal = styled.p`
  margin-top: 30px;
  font-weight: 500 !important;
  font-size: 0.8em;
  margin-bottom: 0px !important;
`;

const BillingSubCoupon = styled.p`
  margin-top: 5px;
  font-weight: 500 !important;
  font-size: 0.8em;
  margin-bottom: 0px !important;
`;

const BillingSucces = styled.div`
  padding: 50px;
  @media (max-width: 1000px) {
    padding: 50px 20px;
  }
`;

const BillingSuccesTitle = styled.h1`
  margin-top: 30px !important;
  margin-bottom: 10px !important;
  color: black !important;

  @media (max-width: 1000px) {
    font-size: 1.3em !important;
    font-weight: 700 !important;
    margin-top: 40px !important !important;
  }
`;

const BillingSuccesDescription = styled.p`
  color: #acacac !important;
  font-weight: 400;
  font-size: 1em !important;
  margin: 30px 0px !important;
`;

const TextError = styled.p`
  color: #ff1f1f !important;
`;

const BillingContainer = styled.div`
  iframe {
    display: initial !important;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  select,
  #credit-card-wrapper {
    display: block;
    font-size: 16px;
    width: 100%;
    border: none;
    height: 40px;
    padding: 0px 14px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    border-radius: 4px;
    background: white;
    outline: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-bottom: 20px;
    appearance: none;
    border: 0;
    box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgb(0 0 0 / 7%), 0 1px 1.5px 0 rgb(0 0 0 / 5%);
    color: hsla(0, 0%, 10%, 0.9);
    font-size: 16px;
    height: 44px;
    line-height: 1.5;
    padding: 8px 12px;
    position: relative;
    transition: box-shadow 0.08s ease-in, color 0.08s ease-in, filter 50000s;
    width: 100%;
  }

  h6 {
    font-size: 0.8em;
    margin-bottom: 10px;
    color: #747574;
    font-weight: 500;
    margin-top: 10px;
  }

  #credit-card-wrapper {
    margin-bottom: 0px;
    div {
      height: 25px;
      padding-top: 3px;
    }
  }

  .cancel-coupon {
    position: absolute;
    margin-top: -51px;
    width: 20px;
    height: 20px;
    float: right;
    right: auto;
    margin-right: 0;
    position: relative;
    margin-right: 70px;
    cursor: pointer;
  }
`;
const BillingHeader = styled.h1``;

const BillingDescription = styled.p``;

const BillingButton = styled.button`
  border: none;
  color: #fff;
  background-color: #5db24a;
  padding: 10px 50px;
  border-radius: 5px;
  margin-top: 30px;
  font-family: Poppins;
  width: 100%;
`;

const BillingInput = styled.input`
  display: block;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  border-radius: 4px;
  background: white;
  outline: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 20px;
  appearance: none;
  border: 0;
  box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgb(0 0 0 / 7%), 0 1px 1.5px 0 rgb(0 0 0 / 5%);
  color: hsla(0, 0%, 10%, 0.9);
  font-size: 16px;
  height: 44px;
  line-height: 1.5;
  padding: 8px 12px;
  position: relative;
  transition: box-shadow 0.08s ease-in, color 0.08s ease-in, filter 50000s;
  width: 100%;
  -moz-appearance: textfield;
`;

const BillingCouponButton = styled.button`
  border: none;
  color: #fff;
  padding: 8px 20px;
  border-radius: 5px;
  float: right;
  right: auto;
  margin-right: 0;
  position: relative;
  margin-bottom: -50px;
  transition: 0.3s linear;

  .spinner-border {
    width: 1.3rem !important;
    height: 1.3rem !important;
  }

  :disabled,
  [disabled] {
    background-color: #d9dfe6;
    box-shadow: none;
    color: #fff;
  }
`;

const BillingSummary = styled.p`
  margin-top: 5px;
  color: #203656 !important;
  margin-bottom: 0px !important;
  font-weight: 600;
`;

export default BillingComponent;
