import AdManager from './adManager';
import { BellFilled } from "@ant-design/icons";
import { notification, Tabs, Tooltip } from "antd";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { Trans, useTranslation } from "react-i18next";
import { BiLike } from "react-icons/bi";
import { MdKeyboardBackspace } from "react-icons/md";
import ReactPlayer from "react-player";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { scroller } from "react-scroll";
import Animate from "react-smooth/lib/Animate";
import { Spinner } from "reactstrap";
import styled from "styled-components";
import AccessWallComponent from "../components/accessWallComponent";
import AddComponent from "../components/addComponent";
import ChartComponent from "../components/chartComponent";
import FastFavoritesModule from "../components/fastFavoritesModule";
import FooterComponent from "../components/footerComponent";
import ForecastWeekComponent from "../components/forecastWeekComponent";
import SfListComponent from "../components/sfListComponent";
import ShareComponent from "../components/shareComponent";
import StreamComponent from "../components/streamComponent";
import spotReducer from "../reducers/spotReducer";
import constant from "../utils/constant";
import utils from "../utils/utils";
import RequestCameraButton from "./requestCameraButton";
import ForecasterComponent from "../components/forecasterComponent";
import ForecastTable from "./forecastTable";
import ForecastNow from "./forecastNow";
import { FaBookmark } from "react-icons/fa";
import { FaRegBookmark } from "react-icons/fa";
import { faBackwardFast, faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import benefitService from "../modules/BenefitService";
import NearBenefitList from "./nearBenefitList";
import SaveTheWavesButton from './saveTheWavesButton';
import FindSpot from './findSpot';

const CamNavBar = (props) => {
  const { tab, spot } = props;
  const { country, city, url } = spot;
  let history = useHistory();
  const [tabSelected, setTabSelected] = useState();

  const items = [
    {
      key: "1",
      tab: "camera1",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img crossOrigin="anonymous" className={`icon-cam`} src={require("../assets/icons/grey-cam.svg")} alt="cam-icon" style={{ marginRight: "10px" }} />
          Cámara 1
          <Divider />
        </div>
      ),
      route: utils.getSpotUrl(country, city, url) + "/camera1",
    },
    {
      key: "2",
      tab: "camera2",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img crossOrigin="anonymous" className={`icon-cam`} src={require("../assets/icons/grey-cam.svg")} alt="cam-icon" style={{ marginRight: "10px" }} />
          Cámara 2
        </div>
      ),
      route: utils.getSpotUrl(country, city, url) + "/camera2",
    },
  ];

  const searchItemByField = useCallback(
    (field, value) => {
      for (let index = 0; index < items.length; index++) {
        const element = items[index];
        if (element[field] === value) {
          return element;
        }
      }
    },
    [items],
  );

  const checkSelected = useCallback(() => {
    const selectedItem = searchItemByField("tab", tab);
    return selectedItem["key"];
  }, [tab, searchItemByField]);

  useEffect(() => {
    setTabSelected(checkSelected());
  }, [tab, checkSelected]);

  const onChange = (key) => {
    const selectedItem = searchItemByField("key", key);
    history.push(selectedItem["route"]);
  };

  return (
    <>
      <ProfileNavbarContainer>
        <ProfileNavbarList>
          <Tabs activeKey={tabSelected} items={items} onChange={onChange} />
        </ProfileNavbarList>
      </ProfileNavbarContainer>
    </>
  );
};

const Divider = styled.div`
  width: 1px;
  height: 20px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  right: -20px;
`;

const ProfileNavbarContainer = styled.div`
  margin-top: 0px;

  div {
    font-size: 18px;
  }
`;

const ProfileNavbarList = styled.ul`
  padding: 0px 15px;
  margin-top: 0px;
  align-items: center;
  text-align: center;
  width: 100%;
`;

const TooltipButton = ({ title, onClick, children, autoCloseTime = 1500 }) => {
  const [visible, setVisible] = useState(false);
  const closeTimer = useRef(null);

  const handleClick = (event) => {
    setVisible(true);
    if(onClick) onClick(event);
    closeTimer.current = setTimeout(() => setVisible(false), autoCloseTime);
  };

  const handleMouseEnter = () => {
    setVisible(true);
    if (closeTimer.current) clearTimeout(closeTimer.current); // Cancelar cierre si el usuario está haciendo hover
  };

  const handleMouseLeave = () => {
    setVisible(false);
  };

  return (
    <Tooltip title={title} open={visible} >
      <ButtonAction 
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </ButtonAction>
    </Tooltip>
  );
};

const SpotComponent = (props) => {
  const { spot, selectedPosition, user, status, handleRewind, allCams, concatTitle, forcastDays, currentDayForecast, isAppleAccount } = props;
  const { t, i18n } = useTranslation();
  const { BEACH, LAKE } = constant.SPOT_TYPE;
  let history = useHistory();
  const [isWallOpen, setIsWallOpen] = useState(false);
  const [premiumFeature, setPremiumFeature] = useState("");
  const [isSpotFavorite, setSpotFavorite] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [tab, selectedTab] = useState("camera1");
  const [isReturnMinutes, setIsReturnMinutes] = useState(false);
  const [returnMinutesValues, setReturnMinutesValues] = useState(null);
  const [nearBenefits, setNearBenefits] = useState([]);
  const [api] = notification.useNotification();
  const shareModalId = 'shareSpot';
  const openNotification = (placement) => {
    api.open({
      message: t("request_spot_notification_title"),
      description: t("request_spot_notification_description"),
      placement,
      icon: <BiLike />,
    });
  };
  const haveAuto  = spot.spot_type !== LAKE;
  const haveExpert = spot?.forecaster_prono;

  useEffect(() => {
    let url;
    // url = window.location.pathname === `/spots/${spot.url}/camera2` ? "camera2" : window.location.pathname === "/" ? `${selectedPosition}` : "camera1";
    url = window.location.pathname.includes("camera2") ? "camera2" : "camera1";
    selectedTab(url);
  }, [window.location.pathname]);

  useEffect(() => {
    getRewinds();
    if (utils.isSpotFavorite(spot.id, user.favorites)) {
      setSpotFavorite(true);
    }
    spot?.position_camera && selectedTab(spot.position_camera);
    getBenefits();
  }, []);

  const getBenefits = async () => {
    const benefits = await benefitService.getNearBenefits(spot.coordinates);
    setNearBenefits(benefits);
  }

  const getRewinds = async () => {
    if (spot.last_rewinds && spot.last_rewinds.length > 0) {
      setReturnMinutesValues(spot.last_rewinds[0].url);
    }
  };

  const returnTenMiutes = () => {
    if (user && user.isPremium !== undefined) {
      if (user.isPremium === true && returnMinutesValues !== undefined && returnMinutesValues !== null) {
        setIsReturnMinutes(!isReturnMinutes);
      } else if (user.isPremium !== true) {
        setPremiumFeature("camrewind");
        setIsWallOpen(true);
        return;
      }
    } else {
      history.push({
        pathname: "/access",
        search: "",
        state: { status: status, user: user },
      });
    }
  };

  const goToPremium = (feature) => {
    if (user && user.isPremium !== undefined) {
      if (feature === constant.FEATURE_MULTISPOT) {
        goToMultiSpot();
      }
      if (user.isPremium === true || utils.isValidDate(constant.VALIDATE_DAY.camerewind_start, constant.VALIDATE_DAY.camerewind_end)) {
        if (feature === constant.FEAUTRE_CAMEREWIND) {
          handleRewind(true);
        }
        return;
      } else {
        setPremiumFeature(feature);
        setIsWallOpen(true);
        return;
      }
    } else {
      history.push({
        pathname: "/access",
        search: "",
        state: { status: status, user: user },
      });
    }
  };

  const goToMultiSpot = () => {
    spotReducer.saveSpot(spot);
    history.push({
      pathname: "/favorites/all",
      search: "",
      state: { status: status, user: user, spot: spot },
    });
  };

  const goToWaveAlert = () => {
    history.push({
      pathname: "/account/wave-alerts",
      search: `spot_id=${spot.id}&spot_name=${spot.name}`,
      state: { status: status, user: user, spot: spot },
    });
  };

  const removeFavorites = async () => {
    // setIsUpdating(true);
    setSpotFavorite(false);
    let res = await utils.removeFavorite(spot.id, user.favorites);
    if(!res)
      setSpotFavorite(true);
    spotReducer.saveSpot(null);
    setIsUpdating(false);
  };

  const addFavorite = async () => {
    setSpotFavorite(true);
    // setIsUpdating(true);
    if (user && user.favorites !== undefined && utils.canAddMoreFavorites(user.favorites, user.isPremium)) {
      let res = await utils.addFavorite(spot.id, user.favorites, user.isPremium);
      // Si hubo un problema al intentar agregarlo
      if(!res)
        setSpotFavorite(false);
    } else {
      if (user && user.isPremium){
          //es premium y llego al top
        setPremiumFeature(constant.FEATURE_MULTISPOT_TOP);
      }else{
        //no es premium
        setPremiumFeature(constant.FEATURE_MULTISPOT);
      }
      setIsWallOpen(true);
    }
  };

  const goTo = (section) => {
    scroller.scrollTo(section, {
      duration: 300,
      delay: 0,
      smooth: true,
    });
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <Animate to="1" from="0" duration={500} attributeName="opacity">
      
      <Helmet>
        <title>{spot.name + ", " + spot.city + ", " + spot.country + " - " + t("seo_title_page")}</title>
        {spot.surfcam ? (<meta name="description" content={t("meta_description_views_spot_with_camera", { spotName: spot.name })} />)
          : (<meta name="description" content={t("meta_description_views_spot_without_camera", { spotName: spot.name })} />)}
        {spot.surfcam ? (<meta name="og:description" content={t("meta_description_views_spot_with_camera", { spotName: spot.name })} />)
          : (<meta name="og:description" content={t("meta_description_views_spot_without_camera", { spotName: spot.name })} />)}
      </Helmet>
      
      <AccessWallComponent openModal={isWallOpen} setIsWallOpen={setIsWallOpen} feature={premiumFeature} />
      
      <ShareComponent dialogId={shareModalId} url={`${window.location.origin + utils.getSpotUrl(spot.country, spot.city, spot.url)}`} title={t("share_spot_title") + spot.name} separator={" - "} />
      
      <div className="container-xl">
        <div className="row gy-4">
          <div className="relative spot-component-container">
            {/* section header */}
            <div className={`section-header ${!user?.isPremium ? "mobile-mb-0" : ""}`} style={{ marginBottom: allCams.length > 1 ? "0px" : "33px" }}>
            {spot.host && (
                <div style={{fontSize :'large'}}>
                  <sup>Host: {!spot.banner_link ?   
                              spot.host 
                              :
                               <a href={spot.banner_link} target="_blank" rel="noopener noreferrer">{spot.host}</a>}
                  </sup>
                </div>
              )}
           {/*  <h6>{spot.city}, {spot.country}</h6>*/}
              <h3 className="section-title up-margin" style={{display:'flex'}}> 
                  <React.Fragment>
                    <Tooltip title={!spot.surfcam ? t("no_camera_text") : spot.is_rotate_camera ? t("rotate_camera_text") : t("no_rotate_camera_text")}>
                                     
                      <img src={require(!spot.surfcam ? "../assets/icons/not_cam.svg": 
                                          spot.is_rotate_camera ? 
                                                spot.is_premium ? "../assets/icons/cam-rotate_premium.svg": "../assets/icons/cam-rotate.svg" 
                                          : 
                                                spot.is_premium ? "../assets/icons/cam2_premium.svg": "../assets/icons/cam2.svg")} 
                      className="svg-cam" id="no-rotate-camera" alt={""} />
                      
                    </Tooltip>
                  </React.Fragment>  
                  <div className='spot-title-container'>
                    <div style={{display:'grid'}}>
                         <div className='spot-title-country' > {spot.city}, {spot.country}</div>
                         <div className='spot-title-name' >{spot.name}  </div>
                    </div>
                    <div onClick={() => (isSpotFavorite === true ? removeFavorites() : addFavorite())}>
                      {isSpotFavorite === true ? <FaBookmark color={'#203656'} /> : <FaRegBookmark color={'#203656'} />}
                    </div>
                  </div>

                {/*spot.is_premium && <sub className="sub_premium"> premium</sub>fabc41 */}
              </h3>
             
            </div>

            <AdManager adUnit="/23206544098/spot-name"/>

            {/* Multi angle navigation tabs, multiple cameras in the same surf spot */}
            {allCams.length > 1 && <CamNavBar setTab={selectedTab} tab={tab} spot={spot} />}

            <div className="rounded">
              <div className="row gy-5">
                <div className="col-sm-12" style={{ marginTop: "27px" }}>
                  {spot.surfcam && (
                    <>
                      {!isReturnMinutes ? (
                        <StreamComponent
                          src={spot.surfcam_url}
                          lastRewinds={spot.last_rewinds}
                          isFree={spot.is_free}
                          post={spot.img}
                          cameraId={spot.id}
                          surfCamImg={spot.img}
                          warning={spot.haveWarning}
                          isHome={false}
                          isPremium={spot.is_premium}
                          startPremium={spot.start_premium}
                          endPremium={spot.end_premium}
                          guestRestriction={spot.guest_restriction}
                          autoPlay={true}
                        />
                      ) : (
                        <ReactPlayerContainer>
                          <MdKeyboardBackspace onClick={() => setIsReturnMinutes(false)} />
                          <ReactPlayer
                            url={returnMinutesValues}
                            playsinline={true}
                            playing={true}
                            controls={true}
                            loop
                            muted={true}
                            width="100%"
                            height="100%"
                            config={{ file: { attributes: { controlsList: "nodownload" } } }}
                            playIcon={<button>live</button>}
                          />
                        </ReactPlayerContainer>
                      )}

                    </>
                  )}
                  {!spot.surfcam && <RequestCameraButton spot={spot} status={status}></RequestCameraButton>}
                </div>
              </div>
            </div>
            { spot.surfcam && (
              <ButtonActionContainer className="col-sm-12" >
                  <CameraButtonContainer>
                      <TooltipButton title="Cam Rewind" onClick={() => goToPremium(constant.FEAUTRE_CAMEREWIND)}>
                        <img src={require("../assets/icons/camrewind.svg")} alt={""} />
                        <p>{t("spot_view_camrewind_button")}</p>
                      </TooltipButton>

                      <TooltipButton title={t("view_10_minutes_button")} onClick={() => returnTenMiutes()}>
                        <FontAwesomeIcon icon={faBackwardFast} />
                        <p>Fast Rewind</p>
                      </TooltipButton>
                      
                      <TooltipButton title={t("spot_toolbar_wave_alert_button")} onClick={() => goToWaveAlert()}>
                        <BellFilled/>
                        <p>{t("wave_alert_form_create_button")}</p>
                      </TooltipButton>

                      <TooltipButton title={t("share_button_title")} onClick={() => window[shareModalId].showModal()} id="gtm-spot-button-social-share">
                        <FontAwesomeIcon icon={faShare} />
                        <p>{t("share_button_title")}</p>
                      </TooltipButton>

                      <TooltipButton title={t("ubication")} onClick={() => openInNewTab(spot.maps)} id="gtm-spotview-spot-maps">
                        <img src={require("../assets/icons/location.svg")} style={{}} alt={""} />
                        <p>{t("ubication")}</p>
                      </TooltipButton>

                  </CameraButtonContainer>
              </ButtonActionContainer>
            )}
            {spot.surfcam && (
              <>
                { spot.banner_url !== undefined && spot.banner_url !== null && (user == undefined || (user.isPremium !== undefined && user.isPremium == false)) && (
                  <AddComponent img={spot.banner_url} link={spot.banner_link} />
                )}
                { (spot.banner_url == undefined || spot.banner_url == null) && (
                  <AdManager adUnit="/23206544098/spot-camera-buttons"/>
                )}
              </>
            )}

            {/* Find Spots: Near Spots, Same Orientation, Favorites, Searchbar */}
            <ForecastTitle>
                <hr></hr>
            </ForecastTitle>
            <FindSpot spot={spot} status={status}/>
            
            {/* Current Surf Conditions */}
            <ForecastTitle>
              <hr></hr>
              <div className="d-flex content">
                <span>{t("spot_forecast_now_title")}</span>
              </div>
            </ForecastTitle>

            <ForecastNow forcastDay={currentDayForecast} timezone={spot.timezone} type={spot.spot_type} spot={spot} />

            <SourceText>{t("used_models")}</SourceText>

            <div className='spot-aditional-info'>          
              <p className="spot-orientation">
                {t("spot_orientation")}: {spot.orientation}
              </p>
              <div className='vertical-divider'></div>
              <span className='surf-school-container'>
                <p className='spot-orientation'>{t("surf_school")}</p>
                { spot.surf_schools.length > 0 ?
                  <a href={spot.surf_schools[0].school_link} target='_blank' className='link-normalize'>{spot.surf_schools[0].school_name}</a>
                  :
                  <a href={constant["ADD_SURF_SCHOOL_FORM_LINK"][i18n.language]} target='_blank'>{t("add_surf_school")}</a>
                }
              </span>
            </div>

            <SaveTheWavesButton/>
            
            { !spot.surfcam && (
              <Scroll className="col-sm-12" >
                  <ContainerAction>
                    <Tooltip title={t("spot_toolbar_wave_alert_button")}>
                      <ButtonAction onClick={() => goToWaveAlert()}>
                        <BellFilled/>
                        <p>{t("wave_alert_form_create_button")}</p>
                      </ButtonAction>
                    </Tooltip>
                    <Tooltip title={t("share_button_title")}>
                      <ButtonAction onClick={() => window[shareModalId].showModal()} id="gtm-spot-button-social-share">
                      <FontAwesomeIcon icon={faShare} />
                        <p>{t("share_button_title")}</p>
                      </ButtonAction>
                    </Tooltip>
                    <Tooltip title={t("ubication")}>
                      <ButtonAction onClick={() => openInNewTab(spot.maps)} id="gtm-spotview-spot-maps">
                        <img src={require("../assets/icons/location.svg")} style={{}} alt={""} />
                        <p>{t("ubication")}</p>
                      </ButtonAction>
                    </Tooltip>
                  </ContainerAction>
              </Scroll>
            )}

            <AdManager adUnit="/23206544098/spot-current-conditions"/>

            {/* Forecasting */}
            <ForecastTitle>
              <hr></hr>
              <div className="content">
                <span>
                  <Trans 
                    i18nKey={"spot_forecast"}
                    values={{ spotName: spot.name }}
                  />
                </span>
                { (haveAuto || haveExpert) &&
                <div className="d-flex" style={{ marginTop: "15px"}}>
                  { haveExpert && <ForecastButton onClick={() => goTo("expert")} style={{color: "#fff"}}>{t("forcast-expert")}</ForecastButton>}
                  <ForecastButton onClick={() => goTo("forecast-data")} style={{ color: "#fff"}}>{t("forcast-data")}</ForecastButton>
                  { haveAuto && <ForecastButton onClick={() => goTo("forecastWeek-auto")} style={{color: "#fff"}}>{t("forcast-automatic")}</ForecastButton>}
                </div>
                }
              </div>
            </ForecastTitle>

            {/* Forecaster forecast, en el caso de que el spot no traiga el pronostico no se va a mostrar */}
            { haveExpert && 
              <>
                <ForecasterComponent forecast={spot.forecaster_prono}/>
                <AdManager adUnit="/23206544098/spot-expert-forecast"/>
              </>
            }
            
            {/* Chart Altura de la Ola - 10 dias */}
            { spot?.spot_type !== LAKE && 
              <>
                <ChartComponent chartData={utils.getChartData(spot, i18n.language, user.isPremium)} windData={utils.getWindData(spot, i18n.language, user.isPremium, t)} user={user} spotName={spot.name} />
                <AdManager adUnit="/23206544098/spot-data-forecast-graphic"/>
              </>
            }
           
            {/* Toolbar if the spot does not have a camera */}
            
            {/* 10 day detailed forecast */}
            {/* { spot?.spot_type === LAKE && <ForecastTableLakes spot={spot} /> } */}
            <ForecastTable spot={spot} />
            <AdManager adUnit="/23206544098/spot-data-forecast-table"/>
            
            { haveAuto && <ForecastWeekComponent spot={spot} />}

            { !isAppleAccount &&
              <>
                <ForecastTitle>
                <hr></hr>
                <div className="d-flex align-items-center">
                  <span>{t("nier_benefits_title")}</span>
                </div>
                </ForecastTitle> 
                <NearBenefitList benefits={nearBenefits} />
              </>
            }
                        
            {/* News banner if the user is using the Spanish language */}
            { i18n.language && i18n.language == 'es' &&
              <>
                <ForecastTitle>
                  <hr></hr>
                </ForecastTitle>
                <a href="https://noticias.lineup.surf/">
                  <NewsContainer img={require("./../assets/walls/noticias_banner_img.jpeg")}>
                    <p><Trans i18nKey="banner_news_text" /></p>
                  </NewsContainer>
                </a>
              </>
            }

            <AdManager adUnit="/23206544098/spot-footer"/>

          </div>

          {/* Right column current forecast box - only desktop */}
          {/* <ForecastBoxComponent forcastDays={forcastDays} forecast={currentDayForecast} spot={spot} orientation={spot.orientation} timezone={spot.timezone} /> */}
          
          {/* WhatsApp Banner in mobile */}
          
        </div>
      </div>
      <FooterComponent />
    </Animate>
  );
};

const mapStateToProps = (state) => ({
  sponsors: state.sponsors,
  user: state.user,
  status: state.status,
  currentTime: state.currentTime,
  isAppleAccount: state.isAppleAccount,
});

const NewsContainer = styled.div`
  display: flex;
  position: relative;
  height: 120px;
  width: 100%;
  background-size: cover;
  background-image: url(${ (props) => props.img });
  background-position: center;
  border-radius: 8px;
  margin-top: 20px;

  @media(max-width: 999px){
    height: 90px;
  }

  p{
    position: absolute;
    color: #000;
    font-weight: 500;
    font-size: 32px;
    font-family: 'Poppins';
    top: 50%;
    left: 15%;
    transform: translate(-15%, -50%);
    z-index: 999;
    margin: 0;

    @media(max-width: 999px ){
      font-size: 20px;
    }
  }

`

const StreamContainer = styled.div`
  .jwplayer {
    border-radius: 10px !important;
    margin-bottom: 9px !important;
  }

  .jw-wrapper {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .jw-logo {
    margin-top: 20px !important;
    margin-left: 15px !important;
    width: 150px !important;
    height: 50px !important;

    @media (max-width: 1000px) {
      margin-top: 20px !important;
      margin-left: 10px !important;
      width: 150px !important;
      height: 23px !important;
    }
  }

  .jw-related-btn {
    display: none !important;
  }

  .jw-icon-rewind {
    display: none !important;
  }

  .jw-icon-next {
    display: none !important;
  }

  .jw-nextup-container {
    display: none !important;
  }

  .jw-preview {
    background-size: cover !important;
  }

  .jw-icon-volume {
    display: none !important;
  }

  .jw-error-msg {
    display: none !important;
  }

  .jw-slider-container {
    display: none !important;
  }
`;

const SourceText = styled.p`
  margin-top: 20px;
  font-size: 12px;
  font-family: "poppins";
  text-align: center;
  font-style: italic;
`;
const ButtonActionContainer = styled.div`
  @media (max-width: 1000px) {
    overflow-x: scroll;
    max-width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media(min-width: 700px){
    justify-content: center;
  }
`

const Scroll = styled.div`
  @media (max-width: 1000px) {
    overflow-x: scroll;
    max-width: 100%;
    padding: 0;
    height: 65px !important;
    display: flex;
    align-items: center;
  }

  @media(min-width: 700px){
    justify-content: center;
  }
`;

const ContainerAction = styled.div`
  position: relative;
  display: flex;
  float: none;
  justify-content: center;

  @media (max-width: 1000px) {
    width: fit-content;
  }

  button {
    margin: 0 5px;
  }
`;

const CameraButtonContainer = styled.div`
  position: relative;
  display: flex;
  float: none;
  justify-content: center;

  @media (max-width: 999px) {
    width: fit-content;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto;
    grid-gap: 5px;
    justify-items: center;
    align-items: center;
    position: relative;
    float: none;
    justify-content: center;
  }

  @media (min-width: 1000px){
    button {
      margin: 0 5px;
    }
  }
`

const ForecastButton = styled.button`
  width: 140px;
  padding: 8px;
  background-color: #425781;
  border: none;
  border-radius: 5px;
  margin: 0 5px;
`

const ButtonAction = styled.button`
  background-color: #425781;
  min-width: 60px;
  border-radius: 5px;
  text-align: center;
  padding: 0px;
  display: flex;
  align-items: center;
  width: 140px;
  padding: 7px 8px;
  border: none;
  margin: 0px;
  display: flex;
  flex-direction: column;
  color: #fff;
 

  p{ margin: 0; font-size: 14px; margin-top: auto; }

  @media (min-width: 1000px) {
    
    
  }

  @media (max-width: 999px) {
    width: 110px;
   

    p{
      font-size: 11px;
    }
  }


  span {
    text-align: center;
    display: flex;
    width: 100%;
    margin-left: 2px;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  svg {
    color: #fffffff5;
    width: 24px;
    height: 18px;
    margin: auto;
  }

  img{ height: 18px;  }
`;


const RequestCameraContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .request-camera {
    background: #fff;
    border: 1px solid #55555552;
    outline: none;
    margin: 15px 0;
    width: 200px;
    height: 45px;
    border-radius: 5px;
    padding: 8px;
    background-color: rgb(66, 87, 129);
    color: #fff;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const ForecastTitle = styled.div`
  margin-top: 19px;
  position: relative;

  span {
    color: #203656;
    font-size: large;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin-right: 35px;
  }

  hr{
    background: #e4e7ed;
    margin: 2em 0 1em 0;
  }

  @media(max-width: 999px){
    .content{
      flex-direction: column;
    }      
  }
 


  button{
    min-width: unset;
    max-width: unset;
  }
`;

const ReactPlayerContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 9px !important;

  svg {
    position: absolute !important;
    color: #000;
    font-size: 2rem;
    cursor: pointer;
    top: 15px;
    left: 15px;
    z-index: 99999;
    display: none;
    transition: color 5s ease-out;
    border-radius: 15px;

    @media (max-width: 999px) {
      display: block;
      color: #fff;
    }
  }

  :hover,
  svg:hover {
    svg {
      display: block;
      color: #fff;
    }
  }
`;

export default connect(mapStateToProps)(SpotComponent);
