import store from "../utils/store";
import utils from "../utils/utils";

class SearchViewReducer {
    toggleSearchView(){
        store.dispatch({
            type: "IS_SEARCH_VIEW_OPEN",
        });
        utils.toggleScrollBar();
    }
}

const searchViewReducer = new SearchViewReducer();

export default searchViewReducer;