import React, {useEffect} from 'react';
import { Ad } from 'react-ad-manager';
import { connect } from 'react-redux';

const AdManager = (props) => {
  const {adUnit, user} = props;

  useEffect(() => {
    const src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
    if(document.querySelector(`script[src="${src}"`) === null){
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
    
  }, []);

  return (
    <>
     { 
      !user.isPremium &&  
      <div className='ad-manager-container mobile-m-1em'>
        <Ad 
          adUnit={adUnit}
          name={'google-ad-' + adUnit.split('/').slice(-1)}
          size={[
            [[1024, 768], [[970, 90]]],
            [[0, 0], [[320, 100]]],
          ]}
          refreshTimer={30000} // 30s
        />
      </div>
       
     }
    </>
    
  )
}

const mapStateToProps = state => ({
  user: state.user,
})

export default connect(mapStateToProps)(AdManager)
