import { createGlobalStyle } from 'styled-components';

// This shit is literaly anarchism
const HideSearchBar = createGlobalStyle` 
  #nav-bar-mobile {
    display: none;
  }
`;

export default HideSearchBar
