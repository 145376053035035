import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ClipsComponent from "./clipsComponent";
import ReactPlayer from "react-player";
import surfcam from "../modules/surfcam";
import { AiOutlineLeft, AiOutlineClockCircle } from "react-icons/ai";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import LoaderComponent from "./LoaderComponent";
import FooterComponent from "./footerComponent";
import utils from "../utils/utils";
import { scroller } from "react-scroll";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Animate from "react-smooth/lib/Animate";
import constant from "../utils/constant";
import spotService from "../modules/spotService";

const CamRewindComponent = (props) => {
  const { t } = useTranslation();
  let history = useHistory();
  const [isMobile, setIsMobile] = useState(utils.isUsingMobile());
  const [downloading, setDownloading] = useState(false);
  const [rewinds, setRewinds] = useState({});
  const [selectHours, setSelectHours] = useState([]);
  const [currentVideo, setCurrentVideo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSpecialDay, setSpecialDay] = useState(utils.isValidDate(constant.VALIDATE_DAY.camerewind_start, constant.VALIDATE_DAY.camerewind_end))
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    if (selectHours.length === 0) {
      getRewinds();
    }
  }, []);

  const getRewinds = async () => {
    const result = await spotService.getSpotRewinds(props.spot.id);
    if (result !== null && Object.keys(result).length > 0) {
      setRewinds(result);
      setCurrentVideo(result[Object.keys(result)[0]][0].values[0]);
      const hours = Object.keys(result).map( (date, index) => {
        const resultDate = new Date(date);
        resultDate.setDate(resultDate.getDate() + +1);
        return {
          name: index === 0 ? "Hoy" : index === 1 ? "Ayer" : resultDate.toLocaleString("es-uy", { weekday: "long" }) + " " + resultDate.getDate(),
          key: date,
        };
      });

      setSelectHours(hours);
      setIsLoading(false);
    } else {
      setRewinds([]);
      setIsLoading(false);
    }
  };

  const setVideo = (data) => {
    setCurrentVideo(data);
    if (isMobile === true) {
      scroller.scrollTo("camrewind", {
        duration: 500,
        delay: 100,
        smooth: true,
      });
    }
  };

  const handleDownload = async () => {
    setDownloading(true);
    const response = await fetch(currentVideo.url);

    const contentLength = response.headers.get("Content-Length");
    const totalSize = parseInt(contentLength, 10);
    let loaded = 0;

    const reader = response.body.getReader();
    const chunks = [];

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      
      chunks.push(value);
      loaded += value.length;
      setProgress((loaded / totalSize) * 100);
    }

    const blob = new Blob(chunks, { type: "video/mp4" });
    const blobUrl = URL.createObjectURL(blob);

    const [hh, mm, ...rest] = currentVideo?.hour?.split(":"); 
    const countryName = (props.spot?.country_code ?? props.spot?.country ?? "").replace(" ", "-");
    const spotUrl = props.spot.url + (props.spot?.multicam ? `-${props.spot.position_camera}` : "");
    const downloadName = `${countryName}-${spotUrl}-${currentVideo.day}-${hh}-${mm}.mp4`;
  
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = downloadName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);
    setDownloading(false);
    setProgress(0);
  };
  
  if (props.user && (props.user.isPremium !== true && !isSpecialDay)) {
    history.push({
      pathname: "/premium",
      search: "",
      state: { status: props.status, user: props.user },
    });
  }

  return (
    <>
      {isLoading === true ? (
        <LoaderComponent />
      ) : rewinds.length === 0 ? (
        <Animate to="1" from="0" duration={500} attributeName="opacity">
          <div className="row gy-4">
            <div className="col-lg-8" style={{padding: "15px"}}>
              <SpotTitle className="section-title up-margin" onClick={() => props.goback(true)} id="camrewind">
                <AiOutlineLeft /> {props.spot.name} - {props.spot.host}
              </SpotTitle>
              <CamRewindTitle className="section-title">{t("cam_rewind_title")}</CamRewindTitle>

            </div>
          </div>
          <NoRewindsContainer>
            <img crossOrigin="anonymous" src={require("../assets/icons/not_cam.svg")} />
            <p>{t("cam_rewind_no_clips")}</p>
          </NoRewindsContainer>
        </Animate>
      ) : (
        <>
          {(props.user.isPremium === true || isSpecialDay) && (
            <Animate to="1" from="0" duration={500} attributeName="opacity">

              <div className="row gy-4">
                <div className="col-lg-8" style={{padding: "15px"}}>
                  <SpotTitle className="section-title up-margin" onClick={() => props.goback(true)} id="camrewind">
                    <AiOutlineLeft /> {props.spot.name} - {props.spot.host}
                  </SpotTitle>
                  <CamRewindTitle className="section-title">{t("cam_rewind_title")}</CamRewindTitle>
                  <CamRecord>
                    <ReactPlayer
                      url={currentVideo.url}
                      playsinline={true}
                      playing={true}
                      controls={true}
                      loop
                      muted={true}
                      width="100%"
                      height="auto"
                      config={{ file: { attributes: { controlsList: "nodownload" } } }}
                    />
                    <DownloadBox>
                      <ClipInfo>
                        <AiOutlineClockCircle /> {currentVideo["day"]} {currentVideo["hour"]}
                      </ClipInfo>
                      <ButtonDownload 
                        disabled={downloading} 
                        className="download-button" 
                        style={{
                          background: `linear-gradient(
                            to right,
                            #ffa53c 0%, 
                            #ffa53c ${progress}%, 
                            #425781 ${progress}%, 
                            #425781 100%
                          )`
                        }}
                        onClick={handleDownload}>
                        {downloading ? t("cam_rewind_downloading") : t("cam_rewind_download_clip")}
                      </ButtonDownload>
                    </DownloadBox>
                  </CamRecord>
                </div>
                <div className="col-lg-4">
                  <div className="section-header up-margin">
                    <h3 className="section-title">{t("cam_rewind_select_clip")}</h3>
                    <CamRewindSubTitle>{t("cam_rewind_select_clip_description")}</CamRewindSubTitle>
                  </div>
                  <ClipsComponent hours={selectHours} rewinds={rewinds} setVideoUrl={setVideo} currentVideo={currentVideo} />
                </div>
              </div>
              <FooterComponent />
            </Animate>
          )}
        </>
      )}
    </>
  );
};

const NoRewindsContainer = styled.div`
  text-align: center;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999;
  top: 40%;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;

  p {
    font-family: Poppins;
    font-family: Poppins;
    color: #a8a8a8;
    margin-top: 15px;
    padding: 0px 20px;
  }

  img {
    width: 100px;
    opacity: 0.3;
  }
`;

const ClipInfo = styled.p`
  font-family: Poppins;
  font-size: 0.9em;
  margin-top: 5px;
  color: #959595;

  @media (max-width: 1000px) {
    margin-top: 12px;
  }

  svg {
    width: 18px;
    height: 18px;
    margin-top: -4px;
  }
`;

const ButtonDownload = styled.button`
  background-color: #425781;
  border: none;
  font-size: 0.9em;
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  right: 15px;
  margin-top: 4px;
  font-family: "Poppins";
  position: relative;

  :hover {
    color: #fff;
    text-decoration: none;
  }

  :disabled {
    color: #fff;
    background-color: #425781;
  }

  svg {
    width: 25px;
    height: 25px;
  }

  .spinner-border {
    width: 1.8rem;
    height: 1.8rem;
  }
`;

const DownloadBox = styled.div`
  display: flex;
  justify-content: space-between;
  video {
    border-radius: 10px;
  }

  @media (max-width: 1000px) {
    display: block !important;
    text-align: center !important;

    p {
      font-size: 0.8em !important;
      text-align: left;
      padding-top: 10px;
    }

    button {
      position: initial;
      width: 100%;
    }
  }
`;

const CamRecord = styled.div`
  video {
    border-radius: 10px;
    min-height: 411px;
  }

  @media (max-width: 1000px) {
    video {
      border-radius: 10px;
      min-height: 215px;
      object-fit: cover;
    }
  }
`;

const SpotTitle = styled.h4`
  font-size: 1.1em;
  margin-bottom: 10px;
  font-weight: 400;
  cursor: pointer;
`;

const CamRewindTitle = styled.h3`
  font-size: 24px;
  margin: 0;
  margin-bottom: 20px;
  font-weight: 700;
`;

const CamRewindSubTitle = styled.p`
  margin-top: 10px;
  color: #a8a8a8;
  font-size: 0.9em;
  font-family: Poppins;
`;

const mapStateToProps = (state) => ({
  status: state.status,
  user: state.user,
  surfcams: state.surfcams,
});

export default connect(mapStateToProps)(React.memo(CamRewindComponent));
