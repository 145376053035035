import React, { useState, useEffect, useCallback } from "react";
import { GoogleMap, Marker, withGoogleMap, withScriptjs, InfoWindow } from "react-google-maps";
import utils from "../utils/utils";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


const GoogleMarker = (props) => {
  const {clickCallback,id,position, info} = props;
  const [showInfo, setShowInfo] = useState(false);
  const { t } = useTranslation();
  
  const handleMarker = () => {
    setShowInfo(!showInfo);
       
  };
 // options={{ pixelOffset: new window.google.maps.Size(0, 30) }} >
 
  return (
    <Marker 
      position={position}
      onClick={() => {
       // e.preventDefault();
        handleMarker();
        if (!showInfo) 
          clickCallback(setShowInfo);
        else
           clickCallback(null);
      }}>
      {showInfo === true && (
        <InfoWindow>
       
          <div className="infoWindow" onMouseLeave={ () => {setShowInfo(false);} }>
            <span>
              {info.surfcam === true ? <img src={require(info.is_rotate_camera === true ? "../assets/icons/cam-rotate.svg" : "../assets/icons/cam2.svg")} className="svg-cam" id="no-rotate-camera" /> :  
              <img src={require("../assets/icons/not_cam.svg")} className="svg-no-cam" id="no-rotate-camera" />}
             
              <h1>{info.name}</h1>
            </span>
            <p>{t("map_info_window_city")}<span>{info.city}</span></p>
            <p>{t("map_info_window_orientation")}<span>{info.orientation}</span></p>
            <Link className="button-primary" to={utils.getSpotUrl(info.country, info.city, info.url) + (info.multicam === true ? "/" + info.position_camera : "")} style={{color: "#fff", textDecoration: "none"}}>
              {t("map_info_window_button")}
            </Link>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
};


const MapComponent = (props) => {
  const { spots, country } = props;
  const [markers, setMarkers] = useState([]);

  const countriesCoordinates = {
    Argentina: { lat: -42.649444, lng: -62.303056, zoom: 4 },
    Brasil: { lat: -14.030539, lng: -46.743580, zoom: 5 },
    Chile: { lat: -35.093790, lng: -72.327260, zoom: 4 },
    Colombia: { lat: 6.374062, lng: -76.174326, zoom: 6 },
    "Costa Rica": { lat: 9.886047, lng: -84.220308, zoom: 8 },
    "Perú": { lat: -10.466305, lng: -76.405026, zoom: 6 },
    "El Salvador": { lat: 13.494425, lng: -88.875660, zoom: 9 },
    Uruguay: { lat: -34.49574539505393, lng: -55.1932903245301, zoom: 7 }
  };

  var actualMarkerInfoState= null;

  const markerClickCallback = useCallback((clickedMarkerInfoState) => {// callback que oculta el infowondow anterior cuando se clickea uno nuevo

    if (actualMarkerInfoState) actualMarkerInfoState(false); 
    actualMarkerInfoState=clickedMarkerInfoState;

   // return 0
  }, [markers]);

  useEffect(() => {
    spots != null && spots != undefined && setMarkers(utils.getCoordinates(spots, country));
  }, [spots, country]);

  const MyMapComponent = withScriptjs(
    
    withGoogleMap((props) => (
      <GoogleMap center={countriesCoordinates[country]} zoom={countriesCoordinates[country].zoom}>
        {markers.length > 0 && props.isMarkerShown && (
          <>
            {" "}
            {markers.map((spot, index) => (
              <GoogleMarker clickCallback={markerClickCallback} key={index} id={index} position={spot.position} info={spot.info} />
            ))}{" "}
          </>
        )}
      </GoogleMap>
    )),
  
    );


  return (
      <MyMapComponent
        isMarkerShown
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAXcJj7-vsGZITCDV3KWwtrxazy6qK37is&map_ids=817fc4603700c801&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `600px`, borderRadius: "20px", overflow: "hidden" }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
  );
};

export default MapComponent;
