import React, {useState} from "react";
import { useEffect, useRef } from "react";

const useInterval = (callback, delay, isRunning = true) => {
  const savedCallback = React.useRef();
  const interval = React.useRef();
  const tick = () => {
    savedCallback.current();
  };

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    
    if (delay && isRunning) {
      interval.current = setInterval(tick, delay);
      return () => clearInterval(interval.current);
    }
    else {
      if(interval.current) clearInterval(interval.current)
    }

  }, [delay, isRunning]);
};

export default useInterval