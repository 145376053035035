import React, {useState} from 'react';
import '../utils/styles/findSpot.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faCompass, faBookmark, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import SfListComponent from './sfListComponent';
import FastFavoritesModule from './fastFavoritesModule';
import searchViewReducer from '../reducers/SearchViewReducer';
import { useTranslation } from 'react-i18next';

const FindSpot = (props) => {
  const {spot, status} = props;
  const [activeOption, setActive] = useState("findNear");
  const {t} = useTranslation();
  const isLogged = (status === 'logged')

  const history = useHistory();
  const navOptions = [
    {
        action: "findNear",
        icon: <FontAwesomeIcon icon={faLocationDot} />,
        able: true,
    },
    {
        action: "findFavorites",
        icon: <FontAwesomeIcon icon={faBookmark} />,
        able: isLogged,
    },
    {
      action: "findSameOrientation",
      icon: (
        <>
          <FontAwesomeIcon icon={faCompass} />
          <span>{spot.orientation}</span>
        </>
      ),
      able: true,
    },
  ];

  const handleNav = ({action, able}) => {
    if(!able) return;

    if(action === "findSameOrientation"){
        history.push({
            pathname: `/spots/country/${props.spot.country}/${props.spot.orientation}`,
            search: window.location.search,
        });
        return;
    }

    setActive(action);
  }

  const handleOptionClassName = (option) => {
    let res = "";
    if(option.action === activeOption) res += " active";
    if(!option.able) res += " deactive";
    return res;
  }

  return (
    <div id="find-spot-container">
        <div className='title-container'>
            <p className='find-spot-title'>{t("nav_spots")}</p>
            <ul className='find-spot-nav'>
                {navOptions.map( option => <li className={handleOptionClassName(option)} onClick={ () => handleNav(option) } >{option.icon}</li> )}
                <li onClick={ () => searchViewReducer.toggleSearchView() } ><FontAwesomeIcon icon={faMagnifyingGlass} /></li>
            </ul>
        </div>

        
        {activeOption === "findNear" && <SfListComponent selectedCam={spot} sfs={spot.near_cameras} /> }
        {activeOption === "findFavorites" && <FastFavoritesModule /> }
    </div>
  )
}

export default FindSpot;
