import React from "react";
import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import AccessWallComponent from "./accessWallComponent";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import spotService from "../modules/spotService";
import useScroll from "../hooks/useScroll";
import SpotCard from "./spotCard";
import constant from "../utils/constant";
import store from "../utils/store";

const FastFavoritesModule = (props) => {
  const { user, favorites = [] } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const [favoritesList, setFavoritesList] = useState(favorites);
  const [isWallOpen, setIsWallOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [cachedSpot, setCachedSpot] = useState(null);
  const rendered = useRef(false);
  const container = useRef();
  const { scrollToNext, scrollToPrevious, scrollCount } = useScroll("#favorite-list div:nth-of-type(1)", container);

  useEffect(() => {
    if((favoritesList?.length > 0) && handleChanges() ) setIsLoading(false); 
    else getFavoritesSpots(user.favorites);
  }, []);

  useEffect(() => {
    if(user?.favorites && rendered.current) handleCachedSpot();
    else rendered.current = true;
  }, [user.favorites])

  const handleCachedSpot = () => {
    let userFavorites = user?.favorites?.split(',').filter(Boolean) ?? [];

    if(favoritesList.length > userFavorites.length){ //removing the favorite we are watching now
      if(cachedSpot) {
        const res = favoritesList.filter(spot => spot.id != cachedSpot.id);
        setFavoritesList(res);
        storeFavorites(res);
      }
      else {
        const targetSpot = favoritesList.filter( spot => !userFavorites.includes( String(spot.id) ));
        if(targetSpot.length > 0) setCachedSpot(targetSpot[0]);
        const res = favoritesList.filter(spot => userFavorites.includes( String(spot.id) ))
        setFavoritesList(res);
      }
    }
    else { //adding new spot
      if(cachedSpot) {
        const res = [...favoritesList, cachedSpot]
        setFavoritesList(res);
        storeFavorites(res);
      }
      else getFavoritesSpots(user.favorites);
    }
  }

  const handleChanges = () => {
    let userFavorites = user?.favorites?.split(',').filter(elem => elem != '').sort();
    let storedFavorites = favorites?.map(spt => spt.id).sort();
    return userFavorites.every((val, ind) => val === storedFavorites[ind]);
  }

  const getFavoritesSpots = async (spots) => {
    let favoritesSpotList = [];
    if (!user.favorites) {
      setIsLoading(false);
      return;
    }
    favoritesSpotList = await spotService.getSpots(spots, false, true, true);

    storeFavorites(favoritesSpotList)

    setFavoritesList(favoritesSpotList);
    setIsLoading(false);
  };

  const storeFavorites = (spots) => {
    store.dispatch({
      type: "FAVORITES_SPOTS_FETCHED",
      data: spots,
    });
  }

  const navigateToFavorites = () => {
    history.push("/favorites/all");
  };

  return (
    <div style={{position: "relative"}}>

      <ScrollContainer ref={container} style={{ overflow: "auto", display: "flex", marginTop: "5px", height: "130px" }}>
        <AiOutlineArrowRight
          className="arrow-icon-list icon-rigth spot-card-icon-right"
          onClick={() => scrollToNext()}
          hidden={favoritesList.length === 0 || user.isPremium != true || scrollCount === favoritesList.length - (favoritesList.length < 4 ? 2 : 3)}
        />
        <AiOutlineArrowLeft className="arrow-icon-list icon-left spot-card-icon-left" onClick={() => scrollToPrevious()} hidden={user.isPremium != true || scrollCount === 0} />
        <AccessWallComponent openModal={isWallOpen} setIsWallOpen={setIsWallOpen} 
               feature={((favoritesList) && (favoritesList.length < 4))?constant.FEATURE_MULTISPOT:constant.FEATURE_MULTISPOT_TOP} 
                />
        <div id="favorite-list">
          {isLoading === true ? (
            <>
              <Skeleton height={100} width={330} style={{ marginRight: "30px", borderRadius: "10px" }} />
              <Skeleton height={100} width={330} style={{ marginRight: "30px", borderRadius: "10px" }} />
              <Skeleton height={100} width={330} style={{ marginRight: "30px", borderRadius: "10px" }} />
            </>
          ) : (
            user.name &&
            favoritesList.map((spot, index) => {
              return <SpotCard key={index} spot={spot} />;
            })
          )}
          {!isLoading && favoritesList.length < 4 && (
            <div className="spot-card-items-container" style={{ cursor: "pointer" }} onClick={navigateToFavorites}>
              <div className=" spot-card-items bold" style={{ height: "100px" }}>
                <Link to={"/favorites/all"} style={{ width: "100%", textAlign: "center" }}>
                  {t("add_favorite_button")}
                </Link>
              </div>
            </div>
          )}
        </div>
      </ScrollContainer>
    </div>
  );
};

const CamsBox = styled.div`
  margin-top: 21px;
`;

const CamsTitle = styled.span`
  font-size: 1em;
  padding: 5px 10px;
  padding-left: 10px;
  background-color: black;
  color: #fff;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
`;

const mapStateToProps = (state) => ({
  user: state.user,
  currentTime: state.currentTime,
  favorites: state.favorites,
});

export default connect(mapStateToProps)(FastFavoritesModule);
